import Box from '@shoreag/base/Box';
import React from 'react';
import PropTypes from 'prop-types';
import Pill from '../Pill';

const PillsGroup = ({ wrapperSx, pillSx, data, onRemove, viewOnly }) => {
  return (
    <Box
      sx={{ display: 'flex', flexWrap: 'wrap', mb: -2, mr: -2, ...wrapperSx }}
    >
      {data.map((item, index) => {
        const { label } = item;
        return (
          <Pill
            key={`${label}-${index}`}
            label={label}
            onRemove={() => onRemove(item)}
            sx={pillSx}
            viewOnly={viewOnly}
          />
        );
      })}
    </Box>
  );
};

PillsGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  onRemove: PropTypes.func,
  pillSx: PropTypes.shape({}),
  viewOnly: PropTypes.bool,
  wrapperSx: PropTypes.shape({}),
};

PillsGroup.defaultProps = {
  onRemove: () => null,
  pillSx: {},
  viewOnly: false,
  wrapperSx: {},
};

export default PillsGroup;
