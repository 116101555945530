import Box from '@shoreag/base/Box';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const Pill = ({ sx, label, onRemove, viewOnly }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        bg: 'accentDark',
        borderRadius: 1,
        color: 'white',
        display: 'flex',
        fontWeight: 'normal',
        height: '1.4rem',
        justifyContent: 'center',
        mb: 2,
        minWidth: '1.4rem',
        mr: 2,
        pl: 4,
        pr: viewOnly ? 4 : 2,
        py: 4,
        textTransform: 'none',
        ...sx,
      }}
      variant="buttons.primary"
    >
      <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={label}>
        {label}
      </Box>
      {!viewOnly ? (
        <Icon
          onClick={() => onRemove()}
          svg="minus"
          sx={{
            bg: 'white',
            borderRadius: '50%',
            color: 'accentDark',
            cursor: 'pointer',
            display: 'inline-flex',
            height: '1rem',
            ml: 3,
            px: '.25rem',
            width: '1rem',
          }}
        />
      ) : null}
    </Box>
  );
};

Pill.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  sx: PropTypes.shape({}),
  viewOnly: PropTypes.bool,
};

Pill.defaultProps = {
  onRemove: () => null,
  sx: {},
  viewOnly: false,
};

export default Pill;
