import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const getThemeColorSeries = () => {
  const { colors } = useContext(ThemeContext);
  const visualizationsColors = [...colors.visualizations];
  visualizationsColors.splice(0, 4);
  const colorSeries = [
    colors.accent,
    colors.success,
    colors.warning,
    colors.accentSecondary,
    colors.greenLight,
    colors.limeYellow,
    colors.accentLight,
    colors.error,
    colors.accentSecondaryDark,
    ...visualizationsColors,
  ];
  return colorSeries;
};

export default getThemeColorSeries;
